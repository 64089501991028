import React from "react";
import "./App.css";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import Collection from "./components/Collection";
import Modals from "./components/Modals";
import About from "./components/About";
import Roadmap from "./components/Roadmap";
import Footer from "./components/Footer";

function App() {
  return (
    <>
    <body id="page-top">        
        <Navbar />
        <Header />
        <Collection />
        <Modals />
        <About />
        <Roadmap />
        <Footer />
    </body>
    </>
  );
}

export default App;
