import React from 'react'

function Footer() {
  return (
    <>
        <footer className="footer text-center">
            <div className="container">
                <div className="row">
                    {/* Footer Social Icons */}
                    <div className="col-lg-4 mb-5 mb-lg-0">
                        <h4 className="text-uppercase mb-4">Connect</h4>
                        <a className="btn btn-outline-light btn-social mx-1" href="https://twitter.com/FantomKites"><i className="fab fa-fw fa-twitter"></i></a>
                        <a className="btn btn-outline-light btn-social mx-1" href="https://discord.gg/PnuynzhYM8"><i className="fab fa-fw fa-discord"></i></a>
                    </div>
                    {/* Footer Community Projects */}
                    <div class="col-lg-4">
                        <h4 class="text-uppercase mb-4">Community Projects</h4>
                            <p>
                                <a href="https://thefantomites.com">The Fantomites</a> by Sebastian | <a href="https://yokaichain.com">Yokai Chain</a> by Metal Mallard | <a href="https://www.fafz.app">FAFZ</a> by Bones
                            </p>  
                    </div>
                    {/* Footer Marketplaces */}
                    <div class="col-lg-4">
                        <h4 class="text-uppercase mb-4">Marketplaces</h4>
                        <div class="btn-group" role="group" aria-label="Basic outlined example">
                            <a className="btn btn-md btn-outline-light" href="https://paintswap.finance/marketplace/collections/0x28de30951eb0cd0a54b15ce7a92afea9fb5d6eac"><b>PaintSwap</b></a>
                            <a className="btn btn-md btn-outline-light" href="https://beta.fantom.digital/0x28de30951eB0cD0a54b15Ce7A92AFeA9fb5d6EAc"><b>Fantom Digital</b></a>
                        </div>                              
                        <div class="btn-group" role="group" aria-label="Basic outlined example">
                            <a className="btn btn-md btn-outline-light" href="https://operahouse.online/collection/Fantom%20Kites"><b>Opera House</b></a>
                            <a className="btn btn-md btn-outline-light" href="https://tofunft.com/collection/fantom-kites"><b>tofuNFT</b></a>
                        </div>                              
                    </div>
                </div>
            </div>
        </footer>
        {/* Copyright Section */}
        <div className="copyright py-4 text-center text-white">
            <div className="container"><small>Copyright &copy; Fantom Kites 2022</small></div>
        </div>
    </>
  )
}

export default Footer;