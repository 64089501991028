import React from 'react'

function About() {
  return (
    <section className="page-section bg-primary text-white mb-0" id="about">
            <div className="container">
                {/* About Section Heading */}
                <h2 className="page-section-heading text-center text-uppercase text-white">About</h2>
                {/* Icon Divider */}
                <div className="divider-custom divider-light">
                    <div className="divider-custom-line"></div>
                    <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                    <div className="divider-custom-line"></div>
                </div>
                {/* About Section Content */}
                <div className="row">
                    <div className="col-lg-4 ms-auto"><p className="lead">
                    Fantom Kites is a collection of 2000 randomly generated Kite NFTs. It's a generative art based collection having different layers of designs to define rarity among the collection. It's purely a Art based NFT collection and has no utility associated with it yet. Consider it as collectibles, nothing more nothing less. It's my first ever NFT collection and I am really excited about it. I have chosen Fantom Opera blockchain because it's fast, cheap, reliable and it has a strong community built around it. I came here last year and have seen so many cool projects that inspired me to do something of my own and add value to the ecosystem. It's my hobby to fly kites, whenever I get chance I do it and that's where the idea came from. To be very honest I am neither a developer nor an artist but I now how to get things done. I was thinking about launching my own NFT collection for quite a while but never had courage to take the first step. So when I finally decided to start my own NFT collection project there was many obstacles. Main ones were Coding, Art design and most importantly Community build up or to say promotion.</p></div>
                    <div className="col-lg-4 me-auto"><p className="lead">
                      I had prior knowledge of Coding but I hadn't touched a single line of code for almost 5 years. Tech has evolved so much since then, so many new languages that I don't know. So I started watching some tutorials and it took me a week to be able to understand little bit but enough to start the project. I got the inspiration to start my own NFT collection from <a href="https://fuckingfruits.com/" className="link-light">Fucking Fruits</a> NFT collection a recent project by Sebastian. Fantom community is so supportive specially <a className="link-light" href="https://twitter.com/youngseebi">Sebastian</a>, whenever there's something that I don't understand and I don't find a way on internet I simply go to <a className="link-light" href="https://discord.gg/9ekEq7WWfv">Fantom Digital</a> where Sebastian and his frens lives and I drop my query there. There hasn't been a single time that I didn't get my query resolved. Special thanks to <a className="link-light" href="https://twitter.com/MetalMallard">Metal Mallard</a> and <a className="link-light" href="https://twitter.com/cmxCole">Bones</a> they are really supportive, these guys are working on some cool projects (linked below) do check them out.</p></div>
                </div>
                
            </div>
        </section>
  )
}

export default About;