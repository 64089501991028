import React from 'react'

function Contact() {
  return (
    <section className="page-section" id="roadmap">
            <div className="container">
                {/* Contact Section Heading */}
                <h2 className="page-section-heading text-center text-uppercase text-secondary mb-0">Roadmap</h2>
                {/* Icon Divider */}
                <div className="divider-custom">
                    <div className="divider-custom-line"></div>
                    <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                    <div className="divider-custom-line"></div>
                </div>
                <div>
                <p className="lead">
                  I have nothing to offer right now other than just Art but I will assure you that whatever project I do in future fantom kites holder will always be included and rewarded.
                  It's just the begining of a long journey ahead. I am learning and evolving day by day and hopefully you will see some cool projects after this one.
                  But Fantom Kites will always remind me from where I started. I am entering into WEB 3.0 from this project and I want to go deep into this space and explore.
                </p>

                </div>
            </div>
    </section>
  )
}

export default Contact;