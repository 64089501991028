import React from 'react'

function Header() {
  return (
    <header className="masthead bg-primary text-white text-center">
            <div className="container d-flex align-items-center flex-column">
                {/* Masthead Avatar Image */}
                <img className="masthead-avatar mb-5" src="logo.png" alt="..." id='rotator' />
                {/* Masthead Heading */}
                <div className="text-center mt-4">
                    <a className="btn btn-xl btn-outline-light" href="https://fantomkites.pages.dev">
                      <b>MINT NOW</b>
                    </a>
                </div>
                {/* Icon Divider */}
                <div className="divider-custom divider-light">
                    <div className="divider-custom-line"></div>
                    <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                    <div className="divider-custom-line"></div>
                </div>
                {/* Masthead Subheading */}
                <p className="masthead-subheading font-weight-light mb-0">2K Kites Ready to Fly on Fantom Blockchain</p>
            </div>
    </header>
  )
}

export default Header;
