import React from 'react'

function Modals() {
  return (
   <>
        {/* Portfolio Modal 1 */}
        <div className="portfolio-modal modal fade" id="portfolioModal1" tabIndex={-1} aria-labelledby="portfolioModal1" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header border-0"><button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button></div>
                    <div className="modal-body text-center pb-5">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-8">
                                    {/* Portfolio Modal - Title */}
                                    <h2 className="portfolio-modal-title text-secondary text-uppercase mb-0">Shuriken</h2>
                                    {/* Icon Divider */}
                                    <div className="divider-custom">
                                        <div className="divider-custom-line"></div>
                                        <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                                        <div className="divider-custom-line"></div>
                                    </div>
                                    {/* Portfolio Modal - Image */}
                                    <img className="img-fluid rounded mb-5" src="assets/img/collection/Shuriken.png" alt="..." />
                                    {/* Portfolio Modal - Text */}
                                    <p className="mb-4">Shuriken is a Japanese concealed weapon that was used as a hidden dagger. That means when you see a Shuriken Kite flying in the open sky just don't try to mess with it.</p>
                                    <button className="btn btn-primary" data-bs-dismiss="modal">
                                        <i className="fas fa-xmark fa-fw"></i>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Portfolio Modal 2 */}
        <div className="portfolio-modal modal fade" id="portfolioModal2" tabIndex={-1} aria-labelledby="portfolioModal2" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header border-0"><button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button></div>
                    <div className="modal-body text-center pb-5">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-8">
                                    {/* Portfolio Modal - Title */}
                                    <h2 className="portfolio-modal-title text-secondary text-uppercase mb-0">Dual Circle</h2>
                                    {/* Icon Divider */}
                                    <div className="divider-custom">
                                        <div className="divider-custom-line"></div>
                                        <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                                        <div className="divider-custom-line"></div>
                                    </div>
                                    {/* Portfolio Modal - Image */}
                                    <img className="img-fluid rounded mb-5" src="assets/img/collection/DualCircle.png" alt="..." />
                                    {/* Portfolio Modal - Text */}
                                    <p className="mb-4">Dual Circle or Circle in Circle is a pretty common design used in Kites. It represents you are tougher from outside but softer inside.</p>
                                    <button className="btn btn-primary" data-bs-dismiss="modal">
                                        <i className="fas fa-xmark fa-fw"></i>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Portfolio Modal 3 */}
        <div className="portfolio-modal modal fade" id="portfolioModal3" tabIndex={-1} aria-labelledby="portfolioModal3" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header border-0"><button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button></div>
                    <div className="modal-body text-center pb-5">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-8">
                                    {/* Portfolio Modal - Title */}
                                    <h2 className="portfolio-modal-title text-secondary text-uppercase mb-0">Star</h2>
                                    {/* Icon Divider */}
                                    <div className="divider-custom">
                                        <div className="divider-custom-line"></div>
                                        <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                                        <div className="divider-custom-line"></div>
                                    </div>
                                    {/* Portfolio Modal - Image */}
                                    <img className="img-fluid rounded mb-5" src="assets/img/collection/Star.png" alt="..." />
                                    {/* Portfolio Modal - Text */}
                                    <p className="mb-4">Pretty simple and straight forward design used to represent the Kite is headed towards galaxy beyond the moon.</p>
                                    <button className="btn btn-primary" data-bs-dismiss="modal">
                                        <i className="fas fa-xmark fa-fw"></i>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Portfolio Modal 4 */}
        <div className="portfolio-modal modal fade" id="portfolioModal4" tabIndex={-1} aria-labelledby="portfolioModal4" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header border-0"><button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button></div>
                    <div className="modal-body text-center pb-5">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-8">
                                    {/* Portfolio Modal - Title */}
                                    <h2 className="portfolio-modal-title text-secondary text-uppercase mb-0">Rocket</h2>
                                    {/* Icon Divider */}
                                    <div className="divider-custom">
                                        <div className="divider-custom-line"></div>
                                        <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                                        <div className="divider-custom-line"></div>
                                    </div>
                                    {/* Portfolio Modal - Image */}
                                    <img className="img-fluid rounded mb-5" src="assets/img/collection/Rocket.png" alt="..." />
                                    {/* Portfolio Modal - Text */}
                                    <p className="mb-4">This one is really popular and it's my favorite design, sharp head and fast speed helps it win in most Kite Battles.</p>
                                    <button className="btn btn-primary" data-bs-dismiss="modal">
                                        <i className="fas fa-xmark fa-fw"></i>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Portfolio Modal 5 */}
        <div className="portfolio-modal modal fade" id="portfolioModal5" tabIndex={-1} aria-labelledby="portfolioModal5" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header border-0"><button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button></div>
                    <div className="modal-body text-center pb-5">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-8">
                                    {/* Portfolio Modal - Title */}
                                    <h2 className="portfolio-modal-title text-secondary text-uppercase mb-0">MoonStar</h2>
                                    {/* Icon Divider */}
                                    <div className="divider-custom">
                                        <div className="divider-custom-line"></div>
                                        <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                                        <div className="divider-custom-line"></div>
                                    </div>
                                    {/* Portfolio Modal - Image */}
                                    <img className="img-fluid rounded mb-5" src="assets/img/collection/MoonStar.png" alt="..." />
                                    {/* Portfolio Modal - Text */}
                                    <p className="mb-4">When you want to back and forth between Moon and Stars this one is for you. Used to represent expertise, if you got one fly it higher else you don't deserve it.</p>
                                    <button className="btn btn-primary" data-bs-dismiss="modal">
                                        <i className="fas fa-xmark fa-fw"></i>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* Portfolio Modal 6 */}
        <div className="portfolio-modal modal fade" id="portfolioModal6" tabIndex={-1} aria-labelledby="portfolioModal6" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header border-0"><button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button></div>
                    <div className="modal-body text-center pb-5">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-8">
                                    {/* Portfolio Modal - Title */}
                                    <h2 className="portfolio-modal-title text-secondary text-uppercase mb-0">Skate Fish</h2>
                                    {/* Icon Divider */}
                                    <div className="divider-custom">
                                        <div className="divider-custom-line"></div>
                                        <div className="divider-custom-icon"><i className="fas fa-star"></i></div>
                                        <div className="divider-custom-line"></div>
                                    </div>
                                    {/* Portfolio Modal - Image */}
                                    <img className="img-fluid rounded mb-5" src="assets/img/collection/SkateFish.png" alt="..." />
                                    {/* Portfolio Modal - Text */}
                                    <p className="mb-4">Some call it "Kite inside Kite" but I call it Skate Fish and it looks like one. Skates, which lack stinging spines, are completely harmless to humans and can be handled when necessary without fear.</p>
                                    <button className="btn btn-primary" data-bs-dismiss="modal">
                                        <i className="fas fa-xmark fa-fw"></i>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Modals;